import React from "react";
import Layout from "../layout";
import Slider from "../slider/slider";
// import OurExperts from '../OurExperts'
// import Testimonials from '../Testimonials'

function Home() {
  return (
    <Layout>
      <Slider />
    </Layout>
  );
}

export default Home;
